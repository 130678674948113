import GlobalComponents from "./globalComponents";

import "../assets/vendor/nucleo/css/nucleo.css";
import "../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/scss/argon.scss";
import '../assets/scss/element-vars.scss';
import "../assets/scss/style.css";
import "../assets/scss/animate.css";

import "../assets/scss/front-style.css";

export default {
  install(app) {
    app.use(GlobalComponents);
  },
};
