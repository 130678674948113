import { createStore } from 'vuex'
import { useCookies, globalCookiesConfig } from 'vue3-cookies'
import createPersistedState from 'vuex-persistedstate'

globalCookiesConfig({
  expireTimes: '60d',
  path: '; path=/',
  domain: '',
  secure: false,
  sameSite: '; SameSite=Lax'
})

import { configs } from '@/utils/configs'

import SecureLS from '@/libs/secure-ls'

const { cookies } = useCookies()
const secureLocalStorage = new SecureLS({ isLocalStorage: true, isCompression: true, encodingType: 'rc4', encryptionSecret: configs.encryptionSecret })
const secureSessionStorage = new SecureLS({ isLocalStorage: false, isCompression: true, encodingType: 'rc4', encryptionSecret: configs.encryptionSecret })

import { config } from './config'
import { page } from './page'
import { translation } from './translation'
import { user } from './user'
import { consent } from './consent'

const persistedCookiesStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['consent'],
  storage: {
    getItem: (key) => cookies.get(key),
    setItem: (key, value) => cookies.set(key, value),
    removeItem: (key) => cookies.remove(key)
  }
})

const persistedLocalStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['translation', 'user'],
  storage: {
    getItem: (key) => secureLocalStorage.get(key),
    setItem: (key, value) => secureLocalStorage.set(key, value),
    removeItem: (key) => secureLocalStorage.remove(key)
  }
})

const persistedSessionStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['config', 'page'],
  storage: {
    getItem: (key) => secureSessionStorage.get(key),
    setItem: (key, value) => secureSessionStorage.set(key, value),
    removeItem: (key) => secureSessionStorage.remove(key)
  }
})

export const store = createStore({
  modules: {
    config,
    translation,
    page,
    user,
    consent
  },
  plugins: [persistedLocalStorage, persistedSessionStorage, persistedCookiesStorage]
})

export function useStore() {
  return store
}

export default store
