import $router from '@/router'
import { store } from '@/store'
import { helpers } from '@/utils/helpers'

const router = {
  changeRoute(url, query) {
    $router.push({ path: url, query: query || {} })
  },
  replaceRoute(url, query) {
    $router.replace({ path: url, query: query || {} })
  },
  goBack() {
    if ($router?.currentRoute?.value?.meta?.isApplication && $router?.currentRoute?.value?.query?.view) {
      $router.replace({ path: '/viewform', query: { key: $router?.currentRoute?.value?.query?.key } })
    } else if ($router?.currentRoute?.value?.meta?.back) {
      $router.replace({ path: $router?.currentRoute?.value?.meta?.back, query: {} })
    } else {
      $router.go(-1)
    }

    // let historyList = store.getters['page/getData']?.HistoryList
    // if (historyList.length) {
    //   let url = historyList[historyList.length - 1].split('?')[0];
    // } else {
    //   $router.replace({ path: '/dashboard', query: {} })
    // }
  },
  reload() {
    $router.go()
  }
}

const install = (app) => {
  app.config.globalProperties.$r = router
}

export { install as default, router }
