import BaseInput from "../components/BaseInput";
import Card from "../components/Card";
import Modal from "../components/Modal";
import StatsCard from "../components/StatsCard";
import Sidebar from "../components/SidebarPlugin/SideBar";
import SidebarItem from "../components/SidebarPlugin/SidebarItem";

import ElementPlus from 'element-plus';
import VueSignaturePad from 'vue-signature-pad';

const GlobalComponents = {
  install(app) {
    app.component("BaseInput", BaseInput);
    app.component("Card", Card);
    app.component("Modal", Modal);
    app.component("StatsCard", StatsCard);
    app.component("SideBar", Sidebar);
    app.component("SidebarItem", SidebarItem);

    app.use(ElementPlus);
    app.use(VueSignaturePad);
  },
};

export default GlobalComponents;
