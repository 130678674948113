import { get, isValidResponse } from '@/utils/axios'

const data = {
  statusList: [],
  envConf: {}
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    const ret = await get(`/auth/config`, {})
    if (!isValidResponse(ret)) return
    context.commit('UPDATE_DATA', { envConf: ret.Configuration })
  }
}

export const config = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
