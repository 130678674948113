import { createApp, Vue } from 'vue'
import { helpers } from '@/utils/helpers'
import App from '@/App.vue'
import utils from '@/utils'

const mainApp = createApp(App)
utils(mainApp)

import router from '@/router'
import ArgonDashboard from '@/plugins/argon-dashboard'
import Notifications from '@kyvg/vue3-notification'
import { Vue3Mq } from 'vue3-mq'

mainApp.use(Notifications)
mainApp.use(router)
mainApp.use(ArgonDashboard)
mainApp.use(Vue3Mq, {
  breakpoints: {
    xsmall: 0,
    small: 768,
    medium: 992,
    large: 1200
  }
})

mainApp.config.errorHandler = (err, vm, info) => {
  helpers.catchError(err)
}

// VUEX Store
import store from '@/store/index.js'
mainApp.use(store)

mainApp.mount('#app')
