import { store } from '@/store'

const translation = {
  swapValues(Bale, MessageInvariant) {
    let str = MessageInvariant
    for (let field of Object.keys(Bale)) {
      if (Bale[field]) {
        str = str.replace(`{${field}}`, Bale[field])
        str = str.replace(`{${field.toUpperCase()}}`, Bale[field])
      }
    }
    return str
  },
  getLanguage() {
    let languageCode = store.getters['translation/getData']?.languageCode
    return languageCode
  },
  translateCountry(item) {
    return translation.translate(`LBL_COUNTRY_TRANS_C_${item.country}`, item?.label)
  },
  translate(translationCode, fallbackTrans) {
    let languageCode = store.getters['translation/getData']?.languageCode
    let translationName = store.getters['translation/getData']?.translations?.[languageCode]?.[translationCode] || fallbackTrans || translationCode
    return translationName
  }
}

const install = (app) => {
  app.config.globalProperties.$t = translation
}

export { install as default, translation }
