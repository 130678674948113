import { store } from '@/store'
import { post, get, isValidResponse, formulateUrl } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { router } from '@/utils/router'
import { translation } from '@/utils/translation'

import _ from 'lodash'
import { ElMessageBox } from 'element-plus'

const confirmable = ['ARCHIVE', 'UNARCHIVE', 'RETURN', 'REJECT', 'GENERATE_CERT', 'REMINDER']
const inputable = ['MACHFORM_ENTRY']
const remarkable = [
  'CHANGE_TRANX',
  'APPROVE',
  'APPROVE_PROGRESS',
  'REJECT_PROGRESS',
  'CANCEL',
  'REJECT_ASSESSOR',
  'TOPAID',
  'CONFIRM_PAYMENT',
  'TOUNPAID',
  'REFUND',
  'VOID',
  'PAY_NOW',
  'SPECIAL',
  'REGENERATE',
  'PUBLISH',
  'REUPLOAD_CERTIFICATE',
  'UPLOAD_DISCLOSURE',
  'NEXT_CYCLE'
] // actions
const assignable = ['ASSIGN_ASSESSOR', 'ASSIGN_ASSESSOR_ONSITE', 'ASSIGN_INDEPREVIEWER']

const movable = []
const IsAllowedToUpload = ['PAY_NOW', 'PAY_NOW_RENEWAL', 'REUPLOAD_CERTIFICATE', 'UPLOAD_DISCLOSURE']

const statusCertificateLink = {
  //for certificate
  REGENERATE: '/application/certificate/regenerate',
  EXTEND: '/application/certificate/extend',
  ACTIVE: '/application/certificate/status',
  PUBLISHED: '/application/certificate/status',
  TERMINATED: '/application/certificate/status',
  SUSPENDED: '/application/certificate/status',
  EXPIRED: '/application/certificate/status',
  RESUME: '/application/certificate/resume',
  PAY_NOW_RENEWAL: '/application/certificate/paynow',

  TOPAID: '/application/certificate/paid',
  TOUNPAID: '/application/certificate/unpaid'
}

const statusLink = {
  //for application flow,
  REUPLOAD_CERTIFICATE: '/application/certificate/upload',
  UPLOAD_DISCLOSURE: '/application/disclosure/upload',
  CONFIRM_CRB: '/application/crb/confirm',
  REGENERATE: '/application/certificate/regenerate',
  PAY_NOW: '/application/paynow',
  PAY_NOW_ONLINE: '/application/payonline',
  TOPAID: '/application/paid',
  TOUNPAID: '/application/unpaid',
  CONFIRM_PAYMENT: '/application/change/payment/date',
  REFUND: '/application/onlinepayment/cancel/refund',
  VOID: '/application/onlinepayment/cancel/void',
  APPROVE_PROGRESS: '/application/progress/approve',
  REJECT_PROGRESS: '/application/progress/reject',
  ASSIGN_ASSESSOR: '/application/assign',
  ASSIGN_ASSESSOR_ONSITE: '/application/assign',
  ASSIGN_INDEPREVIEWER: '/application/assign',
  APPROVE: '/application/review',
  CHANGE_TRANX: '/application/change/tranx',
  CANCEL: '/application/cancel',
  REJECT_ASSESSOR: '/application/rejected',
  ARCHIVE: '/application/archive',
  UNARCHIVE: '/application/unarchive',
  SPECIAL: '/application/special',
  GENERATE_CERT: '/application/certificate/regenerate',
  PUBLISH: '/application/publish',
  REMINDER: '/application/reminder',
  NEXT_CYCLE: '/application/shownextcycle',
  MACHFORM_ENTRY: '/application/change/machform'
}

const statusMessage = {
  REUPLOAD_CERTIFICATE: 'MSG_SUCC_APPLICATION_CERT_STATE_UPLOAD',
  CONFIRM_CRB: 'MSG_SUCC_APPLICATION_CRB_CONFIRMED',
  APPROVE_PROGRESS: 'MSG_SUCC_APPLICATION_PROGRESS_COMPLETED',
  REJECT_PROGRESS: 'MSG_SUCC_APPLICATION_PROGRESS_REJECTED',
  INPROGRESS: 'MSG_SUCC_APPLICATION_SAVED_DRAFT',
  PENDING: 'MSG_SUCC_APPLICATION_SUBMITTED_PENDING_REVIEW',
  APPROVE: 'MSG_SUCC_APPLICATION_MOVED_TO_REVIEW',
  CANCEL: 'MSG_SUCC_APPLICATION_CANCELLED',
  REJECT_ASSESSOR: 'MSG_SUCC_APPLICATION_REJECTED',
  ARCHIVE: 'MSG_SUCC_APPLICATION_ARCHIVED',
  UNARCHIVE: 'MSG_SUCC_APPLICATION_UNARCHIVED',
  GENERATE_CERT: 'MSG_SUCC_APPLICATION_CERTIFICATE_GENERATED',
  PUBLISH: 'MSG_SUCC_APPLICATION_MARKED_COMPLETED',
  UPLOAD_DISCLOSURE: 'MSG_SUCCESS',
  NEXT_CYCLE: 'MSG_SUCCESS',
  MACHFORM_ENTRY: 'MSG_SUCCESS',
  CONFIRM_PAYMENT: 'MSG_SUCCESS',
  CHANGE_TRANX: 'MSG_SUCCESS'
}

const flow = {
  afterAction($refs, action = null) {
    if (action == 'REGENERATE') {
      return
    }

    if ($refs?.table?.handleCurrentChange) {
      $refs?.table?.handleCurrentChange()
    } else {
      if (action == 'NEXT_CYCLE') {
        router.changeRoute('/applications')
        return
      }
      router.reload()
    }
  },
  async status(data, action, skip, $refs, isView = false) {
    let { ApplicationKey } = data
    let Message = null
    let isRequiredReason = ['REJECT_ASSESSOR'].indexOf(action) > -1 ? true : false
    let isRequiredTranx = ['APPROVE', 'CHANGE_TRANX'].indexOf(action) > -1 ? true : false

    data.IsApplication = true

    if (action == 'PAY_NOW') {
      Message = 'LBL_PLEASE_UPLOAD_PAYMENT_PROOF'
    }

    if (action == 'NEXT_CYCLE') {
      Message = translation.translate('LBL_SHOW_NEXT_CYCLE')
      Message = Message.replace(/{NO}/, data.HasChildCycle?.ApplicationNo)
      Message = Message.replace(/{START}/, helpers.formatDateTime(data.HasChildCycle?.StartDateTime))
      Message = Message.replace(/{END}/, helpers.formatDateTime(data.HasChildCycle?.EndDateTime))
    }

    if (assignable.indexOf(action) > -1) {
      $refs.select.assign(data, 'ASSESSOR', async (AssignedUserKey, Remark) => {
        let res = await post(statusLink[action], { ...data, Mode: action, AssignedUserKey, Remark })
        if (!isValidResponse(res)) return
        flow.afterAction($refs, action)

        //add alert
        helpers.showMessage({ Code: 'MSG_SUCCESS', Message: translation.translate(statusMessage[action]) })
      })
    } else if (confirmable.indexOf(action) > -1) {
      try {
        await ElMessageBox.confirm(translation.translate('MSG_WARNING_CONFIRM_DELETE'), translation.translate('TTL_WARNING'), {
          confirmButtonText: translation.translate('BTN_OKAY'),
          cancelButtonText: translation.translate('BTN_CANCEL'),
          type: 'warning'
        })
        let res = await post(statusLink[action], { ...data })
        if (!isValidResponse(res)) return
        flow.afterAction($refs, action)

        //add alert
        helpers.showMessage({ Code: 'MSG_SUCCESS', Message: translation.translate(statusMessage[action]) })
      } catch (err) {
        //err
      }
    } else if (inputable.indexOf(action) > -1) {
      $refs.select.inputNow(data, action, async (input) => {
        let res = await post(statusLink[action], { ApplicationKey: data.ApplicationKey, Input: input })
        if (!isValidResponse(res)) return
        flow.afterAction($refs, action)
        //add alert
        helpers.showMessage({ Code: 'MSG_SUCCESS', Message: translation.translate(statusMessage[action]) })
      })
    } else if (remarkable.indexOf(action) > -1) {
      $refs.select.reject(
        async (Remark, Attachment, IsUpdateCertificate, DateTime, PaymentMethod, PayType) => {
          let actionUrl = statusLink[action]
          let IsPayment = false

          //for online payment
          if (action == 'PAY_NOW' && PaymentMethod == 0) {
            actionUrl = statusLink['PAY_NOW_ONLINE']
            IsPayment = true
          }

          let res = await post(actionUrl, { ...data, Remark, Attachment, IsUpdateCertificate, DateTime, PaymentMethod, PayType })
          if (!isValidResponse(res)) return

          //add alert
          if (!IsPayment) {
            flow.afterAction($refs, action)
            helpers.showMessage({ Code: 'MSG_SUCCESS', Message: translation.translate(statusMessage[action]) })
          } else {
            $refs.select.showPaymentNow(res.InvoiceKey, () => {
              flow.afterAction($refs, 'INVOICE')
            })
          }
        },
        Message,
        IsAllowedToUpload.indexOf(action) > -1,
        { ...data, action, isRequiredReason, isRequiredTranx }
      )
    } else if (action == 'CONFIRM_CRB') {
      $refs.select.crb(data, async (CRBForm) => {
        let res = await post(statusLink[action], { ...data, ...CRBForm })
        if (!isValidResponse(res)) return

        $refs.select.crb(null) //hide modal now

        flow.afterAction($refs, action)
        //add alert
        helpers.showMessage({ Code: 'MSG_SUCCESS', Message: translation.translate(statusMessage[action]) })
      })
    } else if (action == 'VIEW_DISCLOSURE') {
      $refs.export.exportContent({
        Mode: 'GENERATE_MACHFORM_ENTRY',
        ApplicationKey: data.ApplicationKey,
        PDFOnly: true
      })
    } else if (action == 'VIEW_SUPPORTING_DOC') {
      $refs.export.exportContent({
        Mode: 'GENERATE_CERT_NO_SUPPORTING_DOC',
        ApplicationKey: data.ApplicationKey,
        PDFOnly: true
      })
    } else if (action == 'VIEW_CERT') {
      $refs.export.exportContent({
        Mode: 'GENERATE_CERTIFICATE',
        Filter: { ApplicationKey: data.ApplicationKey },
        PDFOnly: true
      })
    } else if (action == 'GENERATE_INVOICE') {
      $refs.export.exportContent({
        Mode: 'GENERATE_INVOICE',
        Filter: { ApplicationKey: data.ApplicationKey },
        PDFOnly: true
      })
    } else if (action == 'EDIT') {
      if (!data?.IsAbleToEditForm) {
        return helpers.showWarning({
          Code: 'LBL_NOT_AVAILABLE_FOR_EDIT',
          Message: 'LBL_NOT_AVAILABLE_FOR_EDIT'
        })
      }

      let query = { key: data.ApplicationKey }
      if (isView) {
        query.view = true
      }
      router.changeRoute('/application/edit', query)
    } else if (action == 'REJECT_SELECT') {
      router.changeRoute('/application/checklist/reject', { key: data.ApplicationKey })
    } else if (action == 'PAYMENT' || action == 'INVOICE') {
      router.changeRoute(`/invoice/${data.ApplicationKey}`)
    }
  },
  async statusCertificate(data, action, skip, $refs) {
    let Message = null
    let isRequiredReason = ['TERMINATED', 'SUSPENDED'].indexOf(action) > -1 ? true : false

    if (action == 'PAY_NOW_RENEWAL') {
      Message = 'LBL_PLEASE_UPLOAD_PAYMENT_PROOF'
    }

    if (assignable.indexOf(action) > -1) {
      $refs.select.assign(data, action.replace('ASSIGN_', ''), async (AssignedUserKey) => {
        let res = await post(statusCertificateLink[action], { ...data, Mode: action, AssignedUserKey })
        if (!isValidResponse(res)) return
        flow.afterAction($refs, action)

        //add alert
        helpers.showMessage({ Code: 'MSG_SUCCESS', Message: translation.translate(statusMessage[action]) })
      })
    } else if (action == 'VIEW_CERT') {
      $refs.export.exportContent({
        Mode: 'GENERATE_CERTIFICATE',
        Filter: { ApplicationKey: data.ApplicationKey },
        PDFOnly: true
      })
    } else if (action == 'INVOICE') {
      router.changeRoute(`/certificate/invoice/${data.CertificateKey}`)
    } else {
      $refs.select.reject(
        async (Remark, Attachment) => {
          let res = await post(statusCertificateLink[action], { ...data, Remark, Attachment, CertificateStatusCode: action })
          if (!isValidResponse(res)) return
          flow.afterAction($refs, action)
          //add alert
          helpers.showMessage({ Code: 'MSG_SUCCESS', Message: 'MSG_SUCCESS' })
        },
        Message,
        IsAllowedToUpload.indexOf(action) > -1,
        { ...data, action, isRequiredReason }
      )
    }
  },
  statusCertificateOptions(Item) {
    if (!Item) return []
    let { CertificateStatusCode, ExpiryDateTime, File, IsExpired } = Item
    let User = helpers.getUserData()

    let statusList = {
      CLIENT: [{ command: 'VIEW_CERT', hide: !File, label: 'BTN_VIEW_CERT_STATE', status: ['PUBLISHED', 'ACTIVE'] }],
      SUB_CLIENT: [{ command: 'VIEW_CERT', hide: !File, label: 'BTN_VIEW_CERT_STATE', status: ['PUBLISHED', 'ACTIVE'] }],
      CEO: [{ command: 'VIEW_CERT', hide: !File, label: 'BTN_VIEW_CERT_STATE', status: ['PENDING', 'PUBLISHED', 'ACTIVE'] }],
      STAFF_GENERAL: [
        { command: 'VIEW_CERT', hide: !File, label: 'BTN_VIEW_CERT_STATE', status: ['PENDING', 'PUBLISHED', 'ACTIVE'] },
        //{ command: 'REGENERATE', hide: !User?.IsGrantGeneration, label: 'BTN_GENERATE', status: ['ACTIVE', 'PUBLISHED'] },

        { command: 'ACTIVE', hide: helpers.hasRoles(['BUSINESS', 'DEPUTYCEO', 'COO']), label: 'BTN_UNPUBLISH', status: ['PUBLISHED'] },
        { command: 'PUBLISHED', hide: helpers.hasRoles(['BUSINESS', 'DEPUTYCEO', 'COO']), label: 'BTN_PUBLISH_CERT_STATE', status: ['ACTIVE'] },
        { command: 'TERMINATED', hide: helpers.hasRoles(['BUSINESS', 'DEPUTYCEO', 'COO']), label: 'BTN_TERMINATE', status: ['SUSPENDED'] },
        { command: 'SUSPENDED', hide: helpers.hasRoles(['BUSINESS', 'DEPUTYCEO', 'COO']), label: 'BTN_SUSPEND', status: ['PUBLISHED', 'ACTIVE', 'EXPIRED'] },
        { command: 'EXPIRED', hide: !ExpiryDateTime || helpers.hasRoles(['BUSINESS', 'DEPUTYCEO', 'COO']), label: 'BTN_SET_AS_EXPIRED', status: ['PUBLISHED', 'ACTIVE'] },
        { command: 'RESUME', hide: IsExpired || helpers.hasRoles(['BUSINESS', 'DEPUTYCEO', 'COO']), label: 'BTN_RESUME', status: ['SUSPENDED'] }
      ]
    }

    let options = []
    if (helpers.hasRoles(['CLIENT', 'SUB_CLIENT'])) {
      options = [...(statusList[helpers.getRole()] || [])]
    } else if (helpers.hasRoles(['CEO'])) {
      options = [...(statusList[helpers.getRole()] || [])]
    } else if (helpers.hasRoles(['ADMIN', 'SUB_ADMIN', 'BUSINESS', 'IT', 'SCHEDULING', 'COO', 'DEPUTYCEO'])) {
      options = [...statusList['STAFF_GENERAL']]
    }

    return _.filter(options, (r) => {
      if (!r.hide && r.status && r.status.indexOf(CertificateStatusCode) > -1) {
        return true
      }
      return false
    })
  },
  statusOptions(Item) {
    if (!Item) return []

    let {
      SupportingRelevantDoc,
      HasChildCycle,
      Type,
      CurrentStep,
      AssessmentType,
      StartDateTime,
      ApplicationStatusCode,
      IsPaid,
      IsManual,
      IsCertDateNoManual,
      IsCanGenerate,
      IsSecondAssessment,
      IsShortFlow,
      IsPaymentProof,
      IsGenerated,
      IsPassedForPublishing,
      IsGenerateTriggered,
      IsManualDisclosure,
      IsCOOApproved,
      //to check for Assessor
      IsAuditRecommended,
      AssessorUserId,
      AssessorUserKey,
      //to check for IR
      IsIndepReviewerApproved,
      IndepreviewerUserId,
      IndepreviewerUserKey,
      DisclosureFile,
      //to check for Assess ON-SITE
      IsOnSiteAuditRecommended,
      AssessorOnSiteUserId,
      AssessorOnSiteUserKey,
      IsOnlinePayment,
      PaymentDateTime,
      TransactionNo
    } = Item

    let User = helpers.getUserData()

    let GEN_STAFF_APPROVE_ACTION = 'BTN_APPROVE_APPLICATION'

    if (['ASSESSOR', 'ASSESSOR_ONSITE'].includes(CurrentStep)) {
      GEN_STAFF_APPROVE_ACTION = 'BTN_RECOMMEND_APPLICATION'
    } else if (CurrentStep == 'INDEPREVIEWER') {
      GEN_STAFF_APPROVE_ACTION = 'BTN_AGREE_RECOMMENDATION'
    } else if (CurrentStep == 'COO') {
      GEN_STAFF_APPROVE_ACTION = Type == 'STATEMENT' ? 'BTN_APPROVE_RECOMMENDATION' : 'BTN_ENDORSE_RECOMMENDATION'
    } else if (CurrentStep == 'CEO') {
      GEN_STAFF_APPROVE_ACTION = 'BTN_APPROVE_RECOMMENDATION'
    }

    let assignAssessor = 'BTN_ASSIGN_ASSESSOR'
    let reAssignAssessor = 'BTN_REASSIGN_ASSESSOR'

    if (!IsSecondAssessment) {
      assignAssessor = 'BTN_ASSIGN_AUDITOR'
      reAssignAssessor = 'BTN_REASSIGN_AUDITOR'
    }

    let LABEL_REJECT_PROGRESS = CurrentStep == 'INDEPREVIEWER' && IsSecondAssessment ? 'BTN_RETURN_APPLICATION_TO_2ND_ASSESSOR' : 'BTN_RETURN_APPLICATION'
    if (CurrentStep == 'ASSESSOR_ONSITE') {
      LABEL_REJECT_PROGRESS = 'BTN_RETURN_APPLICATION_TO_1ST_ASSESSOR'
    }

    let statusList = {
      STAFF_GENERAL: [
        { command: 'INVOICE', hide: ['INPROGRESS', 'CANCELLED', 'WAITING'].indexOf(ApplicationStatusCode) > -1, label: 'BTN_VIEW_PAYMENT' },
        { command: 'PUBLISH', hide: !(User?.IsGrantGeneration && IsPassedForPublishing), label: 'BTN_PUBLISH_APPLICATION', status: ['CERTIFIED'] },

        { command: 'REGENERATE', hide: !(User?.IsGrantGeneration && IsGenerateTriggered && !IsShortFlow && !IsManual && IsCanGenerate), label: 'BTN_REGENERATE_CERT', status: ['CERTIFIED', 'COMPLETED'] },
        { command: 'GENERATE_CERT', hide: !(User?.IsGrantGeneration && !IsGenerateTriggered && !IsShortFlow && !IsManual && IsCanGenerate), label: 'BTN_GENERATE_CERT', status: ['CERTIFIED', 'COMPLETED'] },

        { command: 'REUPLOAD_CERTIFICATE', hide: !(User?.IsGrantGeneration && !IsShortFlow && IsManual && IsCanGenerate), label: 'BTN_UPLOAD_CERTIFICATE_STATE', status: ['CERTIFIED', 'COMPLETED'] },
        { command: 'VIEW_CERT', hide: !IsGenerated || helpers.hasRoles(['CLIENT', 'SUB_CLIENT']), label: 'BTN_VIEW_CERT_STATE', status: ['CERTIFIED', 'COMPLETED'] },

        { command: 'UPLOAD_DISCLOSURE', hide: !(User?.IsGrantGeneration && !IsShortFlow && IsManualDisclosure && IsGenerated), label: 'BTN_UPLOAD_DISCLOSURE_DOCUMENT', status: ['CERTIFIED', 'COMPLETED'] },
        { command: 'VIEW_DISCLOSURE', hide: !(IsManualDisclosure && DisclosureFile), label: 'BTN_VIEW_DISCLOSURE_DOCUMENT', status: ['CERTIFIED', 'COMPLETED'] },

        {
          command: 'ARCHIVE',
          hide: AssessmentType != 'INITIAL' || helpers.hasRoles(['CLIENT', 'SUB_CLIENT', 'ASSESSOR', 'COO', 'CEO', 'FINANCE', 'DEPUTYCEO', 'BUSINESS']),
          label: 'BTN_ARCHIVE',
          status: ['PENDING', 'UNDERREVIEW', 'REJECTED_FLOW']
        },
        { command: 'UNARCHIVE', hide: AssessmentType != 'INITIAL' || helpers.hasRoles(['CLIENT', 'SUB_CLIENT', 'ASSESSOR', 'COO', 'CEO', 'FINANCE', 'DEPUTYCEO', 'BUSINESS']), label: 'BTN_UNARCHIVE', status: ['ARCHIVED'] },
        { command: 'CANCEL', hide: helpers.hasRoles(['ASSESSOR', 'COO', 'CEO', 'CLIENT', 'SUB_CLIENT', 'DEPUTYCEO', 'FINANCE']), label: 'BTN_CANCEL_APPLICATION', status: ['INPROGRESS', 'PENDING'] },
        { command: 'REMINDER', label: 'LBL_SEND_REMINDER', hide: !StartDateTime || helpers.hasRoles(['CLIENT', 'SUB_CLIENT', 'FINANCE', 'CEO', 'DEPUTYCEO', 'COO']), status: ['INPROGRESS'] }
      ],
      BUSINESS: [
        { command: 'EDIT', label: 'BTN_EDIT_APPLICATION', status: ['INPROGRESS', 'PENDING', 'UNDERREVIEW', 'REJECTED', 'REJECTED_FLOW'] },
        { command: 'APPROVE', label: 'BTN_CONFIRM_TO_REVIEW', status: ['PENDING'] }
      ],
      SCHEDULING: [
        { command: 'CONFIRM_CRB', label: 'BTN_ASSIGN_CERT_NO_DATE', hide: !(IsCertDateNoManual && !IsCanGenerate), status: ['CERTIFIED'] },
        //{ command: 'VIEW_SUPPORTING_DOC', label: 'BTN_VIEW_SUPPORTING_DOC', hide: !SupportingRelevantDoc, status: ['CERTIFIED', 'COMPLETED'] },

        { command: 'ASSIGN_ASSESSOR', label: AssessorUserId ? reAssignAssessor : assignAssessor, hide: IsAuditRecommended, status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        {
          command: 'ASSIGN_ASSESSOR_ONSITE',
          label: AssessorOnSiteUserId ? 'BTN_REASSIGN_ASSESSOR_ONSITE' : 'BTN_ASSIGN_ASSESSOR_ONSITE',
          hide: !IsAuditRecommended || IsOnSiteAuditRecommended || !IsSecondAssessment,
          status: ['UNDERREVIEW', 'REJECTED_FLOW']
        },
        {
          command: 'ASSIGN_INDEPREVIEWER',
          label: IndepreviewerUserId ? 'BTN_REASSIGN_IR' : 'BTN_ASSIGN_IR',
          hide: (!IsAuditRecommended && !IsSecondAssessment) || (!IsOnSiteAuditRecommended && IsSecondAssessment) || IsIndepReviewerApproved,
          status: ['UNDERREVIEW', 'REJECTED_FLOW']
        },
        { command: 'SPECIAL', hide: !(AssessmentType == 'SURVEILLANCE'), label: 'BTN_CONVERT_TO_SPECIAL', status: ['INPROGRESS'] }
      ],
      FINANCE: [
        { command: 'TOPAID', hide: IsPaid || !IsPaymentProof, label: 'BTN_CHANGE_TO_PAID' },
        { command: 'TOUNPAID', hide: !IsPaid || IsOnlinePayment, label: 'BTN_CHANGE_TO_UNPAID' },
        { command: 'VOID', hide: !(IsPaid && IsOnlinePayment), label: 'BTN_VOID_PAYMENT' },
        { command: 'REFUND', hide: !(IsPaid && IsOnlinePayment), label: 'BTN_VOID_REFUND_PAYMENT' },
        { command: 'CONFIRM_PAYMENT', hide: !(IsOnlinePayment && !PaymentDateTime), label: 'BTN_CONFIRM_PAYMENT_DATE' }
      ],
      STAFF: [
        { command: 'APPROVE_PROGRESS', label: GEN_STAFF_APPROVE_ACTION, status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        {
          command: 'REJECT_SELECT',
          hide: !['ASSESSOR', 'ASSESSOR_ONSITE'].includes(CurrentStep),
          label: CurrentStep == 'ASSESSOR_ONSITE' ? 'BTN_RETURN_APPLICATION_CLIENT' : 'BTN_RETURN_APPLICATION',
          status: ['UNDERREVIEW', 'REJECTED_FLOW']
        },
        {
          command: 'REJECT_PROGRESS',
          hide: !(CurrentStep != 'ASSESSOR'),
          label: LABEL_REJECT_PROGRESS,
          status: ['UNDERREVIEW', 'REJECTED_FLOW']
        },
        {
          command: 'REJECT_ASSESSOR',
          hide: !(['ASSESSOR', 'ASSESSOR_ONSITE'].includes(CurrentStep) && ['INITIAL', 'RENEWAL', 'SPECIAL', 'SURVEILLANCE'].indexOf(AssessmentType) > -1),
          label: 'BTN_REJECT_APPLICATION',
          status: ['UNDERREVIEW', 'REJECTED', 'REJECTED_FLOW']
        }
      ],
      CLIENT: [
        { command: 'EDIT', label: 'BTN_EDIT_APPLICATION', status: ['INPROGRESS', 'REJECTED'] },
        { command: 'GENERATE_INVOICE', label: 'BTN_VIEW_INVOICE', hide: ['INPROGRESS', 'CANCELLED'].indexOf(ApplicationStatusCode) > -1 },
        {
          command: 'PAY_NOW',
          label: 'BTN_PAY_NOW',
          hide: IsPaid || IsPaymentProof || ['INPROGRESS', 'CANCELLED', 'ARCHIVED', 'REJECTED_STAFF'].indexOf(ApplicationStatusCode) > -1
        },
        { command: 'CANCEL', hide: AssessmentType != 'INITIAL', label: 'BTN_CANCEL_APPLICATION', status: ['INPROGRESS'] },
        { command: 'VIEW_CERT', hide: !IsGenerated, label: 'BTN_VIEW_CERT_STATE', status: ['COMPLETED'] }
      ],
      SUB_CLIENT: [
        { command: 'EDIT', label: 'BTN_EDIT_APPLICATION', status: ['INPROGRESS', 'REJECTED'] },
        { command: 'GENERATE_INVOICE', label: 'BTN_VIEW_INVOICE', hide: ['INPROGRESS', 'CANCELLED'].indexOf(ApplicationStatusCode) > -1 },
        {
          command: 'PAY_NOW',
          label: 'BTN_PAY_NOW',
          hide: IsPaid || IsPaymentProof || ['INPROGRESS', 'CANCELLED', 'ARCHIVED', 'REJECTED_STAFF'].indexOf(ApplicationStatusCode) > -1
        },
        { command: 'CANCEL', hide: AssessmentType != 'INITIAL', label: 'BTN_CANCEL_APPLICATION', status: ['INPROGRESS'] },
        { command: 'VIEW_CERT', hide: !IsGenerated, label: 'BTN_VIEW_CERT_STATE', status: ['COMPLETED'] }
      ],
      ADMIN_ACTION: [
        { command: 'MACHFORM_ENTRY', label: 'BTN_CHANGE_MACHFORM_ENTRY', status: ['INPROGRESS', 'PENDING', 'UNDERREVIEW'] },
        { command: 'CHANGE_TRANX', label: 'BTN_CHANGE_TRANX_NO', hide: !TransactionNo },

        { command: 'NEXT_CYCLE', label: 'BTN_SHOW_NEXT_CYCLE', hide: !HasChildCycle, status: ['CERTIFIED', 'COMPLETED'] },

        //from business action
        { command: 'EDIT', label: 'BTN_EDIT_APPLICATION', status: ['INPROGRESS', 'PENDING', 'UNDERREVIEW', 'REJECTED', 'REJECTED_FLOW', 'CERTIFIED', 'COMPLETED'] },
        { command: 'APPROVE', label: 'BTN_CONFIRM_TO_REVIEW', status: ['PENDING'] },

        //for assessor action
        { command: 'APPROVE_PROGRESS', hide: CurrentStep != 'ASSESSOR', label: 'BTN_APPROVE_APPLICATION_AS_ASSESSOR', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        { command: 'REJECT_SELECT', hide: CurrentStep != 'ASSESSOR', label: 'BTN_RETURN_APPLICATION_AS_ASSESSOR', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },

        //for assessor on-site action
        { command: 'APPROVE_PROGRESS', hide: CurrentStep != 'ASSESSOR_ONSITE', label: 'BTN_APPROVE_APPLICATION_AS_ASSESSOR_ONSITE', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        { command: 'REJECT_PROGRESS', hide: CurrentStep != 'ASSESSOR_ONSITE', label: 'BTN_RETURN_APPLICATION_AS_ASSESSOR_ONSITE', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        { command: 'REJECT_SELECT', hide: CurrentStep != 'ASSESSOR_ONSITE', label: 'BTN_RETURN_APPLICATION_CLIENT_AS_ASSESSOR_ONSITE', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },

        //for both assessor and on-site assessor
        {
          command: 'REJECT_ASSESSOR',
          hide: !(['ASSESSOR', 'ASSESSOR_ONSITE'].includes(CurrentStep) && ['INITIAL', 'RENEWAL', 'SPECIAL', 'SURVEILLANCE'].indexOf(AssessmentType) > -1),
          label: 'BTN_REJECT_APPLICATION',
          status: ['UNDERREVIEW', 'REJECTED', 'REJECTED_FLOW']
        },

        //for independent reviewer
        { command: 'APPROVE_PROGRESS', hide: CurrentStep != 'INDEPREVIEWER', label: 'BTN_APPROVE_APPLICATION_AS_IR', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        { command: 'REJECT_PROGRESS', hide: CurrentStep != 'INDEPREVIEWER', label: 'BTN_RETURN_APPLICATION_AS_IR', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },

        //for COO / DEPUTY CEO
        { command: 'APPROVE_PROGRESS', hide: CurrentStep != 'COO', label: Type == 'STATEMENT' ? 'BTN_APPROVE_APPLICATION_AS_COO' : 'BTN_ENDORSE_APPLICATION_AS_COO', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        { command: 'REJECT_PROGRESS', hide: CurrentStep != 'COO', label: 'BTN_RETURN_APPLICATION_AS_COO', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },

        //for CEO
        { command: 'APPROVE_PROGRESS', hide: CurrentStep != 'CEO', label: 'BTN_APPROVE_APPLICATION_AS_CEO', status: ['UNDERREVIEW', 'REJECTED_FLOW'] },
        { command: 'REJECT_PROGRESS', hide: CurrentStep != 'CEO', label: 'BTN_RETURN_APPLICATION_AS_CEO', status: ['UNDERREVIEW', 'REJECTED_FLOW'] }
      ]
    }

    statusList.ADMIN = [...statusList['SCHEDULING'], ...statusList['FINANCE'], ...statusList['ADMIN_ACTION']]
    statusList.SUB_ADMIN = [...statusList['SCHEDULING'], ...statusList['FINANCE'], ...statusList['ADMIN_ACTION']]

    let options = []

    if (helpers.hasRoles(['ASSESSOR', 'COO', 'DEPUTYCEO'])) {
      var procActions = []
      if (
        (helpers.hasRoles(['ASSESSOR']) && CurrentStep == 'ASSESSOR') ||
        (helpers.hasRoles(['ASSESSOR']) && CurrentStep == 'ASSESSOR_ONSITE') ||
        (helpers.hasRoles(['ASSESSOR']) && CurrentStep == 'INDEPREVIEWER') ||
        (helpers.hasRoles(['COO', 'DEPUTYCEO']) && CurrentStep == 'COO')
      ) {
        procActions = statusList['STAFF']

        if (CurrentStep == 'ASSESSOR' && AssessorUserKey != User.UserKey) {
          procActions = []
        }

        if (CurrentStep == 'ASSESSOR_ONSITE' && AssessorOnSiteUserKey != User.UserKey) {
          procActions = []
        }

        if (CurrentStep == 'INDEPREVIEWER' && IndepreviewerUserKey != User.UserKey) {
          procActions = []
        }
      }
      options = [...options, ...procActions, ...statusList['STAFF_GENERAL']]
    } else if (helpers.hasRoles(['CEO'])) {
      var procActions = []
      if (CurrentStep == 'CEO') {
        procActions = statusList['STAFF']
      }
      options = [...options, ...procActions, ...statusList['STAFF_GENERAL']]
    } else {
      options = [...options, ...(statusList[helpers.getRole()] || []), ...statusList['STAFF_GENERAL']]
    }

    return _.filter(options, (r) => {
      if (!r.hide && r.status && r.status.indexOf(ApplicationStatusCode) > -1) {
        return true
      }

      if (!r.hide && !r.status) {
        return true
      }

      return false
    })
  }
}

const install = (app) => {
  app.config.globalProperties.$f = flow
}

export { install as default, flow }
