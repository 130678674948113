import axios from '@/utils/axios'
import configs from '@/utils/configs'
import helpers from '@/utils/helpers'
import translation from '@/utils/translation'
import socket from '@/utils/socket'
import router from '@/utils/router'
import flow from '@/utils/flow'

export default (app) => {
  app.use(axios)
  app.use(configs)
  app.use(helpers)
  app.use(translation)
  app.use(socket)
  app.use(router)
  app.use(flow)
}