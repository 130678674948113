<template>
  <nav
    id="sidenav-main"
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark gen-sidebar-bg"
  >
    <div class="container-fluid">
      <div class="navbar-brand">
        <img src="img/brand/logo_sidebar.png" class="navbar-brand-img" alt="..." />
      </div>

      <div id="sidenav-collapse-main" class="navbar-collapse collapse show">
        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
      </div>

      <a href="javascript:;" @click="show">
        <i
          class="fas fa-question-circle"
          style="font-size: 20px; color: var(--el-color-primary)"
        ></i>
      </a>
    </div>
  </nav>
</template>
<script>
import { extendedConfigs } from "@/utils/configs";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  setup() {
    let { helpText, helpTitle } = extendedConfigs;
    const show = () => {
      ElMessageBox.alert(
        translation.translate(helpText),
        translation.translate(helpTitle),
        {
          confirmButtonText: "OK",
        }
      );
    };
    return { show };
  },
};
</script>
