import { createRouter, createWebHistory } from 'vue-router'
import { store } from '@/store'
import { extendedConfigs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'
import _ from 'lodash'
import { formulateUrl } from '@/utils/axios'

const routes = [
  // {
  //   path: "/:catchAll(.*)",
  //   component: NotFound,
  // },
  {
    path: '/',
    redirect: '/home', //default to dashboard
    component: () => import(/* webpackChunkName: "AuthLayout" */ /* webpackMode: "lazy" */ '@/layout/AuthLayout'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: { auth: false, login: true },
        component: () => import(/* webpackChunkName: "Login" */ /* webpackMode: "lazy" */ '@/views/Login')
      },
      {
        path: '/logout',
        name: 'logout',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "Logout" */ /* webpackMode: "lazy" */ '@/views/Logout')
      },
      {
        path: '/forgot',
        name: 'forgot',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "Forgot" */ /* webpackMode: "lazy" */ '@/views/Forgot')
      },
      {
        path: '/forgot/reset',
        name: 'reset',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "Forgot" */ /* webpackMode: "lazy" */ '@/views/Forgot')
      },
      {
        path: '/register',
        name: 'register',
        meta: { auth: false, login: true },
        component: () => import(/* webpackChunkName: "Register" */ /* webpackMode: "lazy" */ '@/views/Register')
      },
      {
        path: '/register/client',
        name: 'register-client',
        meta: { auth: false, login: true },
        component: () => import(/* webpackChunkName: "RegisterClient" */ /* webpackMode: "lazy" */ '@/views/RegisterClient')
      },
      {
        path: '/register/verified',
        name: 'verified',
        meta: { auth: false, login: true },
        component: () => import(/* webpackChunkName: "Register" */ /* webpackMode: "lazy" */ '@/views/Register')
      }
    ]
  },
  {
    path: '/',
    redirect: 'home', //default to home
    component: () => import(/* webpackChunkName: "FrontendLayout" */ /* webpackMode: "lazy" */ '@/layout/FrontendLayout'),
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { auth: false, absHeader: false, isHome: true },
        component: () => import(/* webpackChunkName: "Home" */ /* webpackMode: "lazy" */ '@/frontend/Home')
      },
      {
        path: '/services',
        name: 'services',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "Services" */ /* webpackMode: "lazy" */ '@/frontend/Services')
      },
      {
        path: '/service/view/:key',
        name: 'service',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "ServiceView" */ /* webpackMode: "lazy" */ '@/frontend/ServiceView')
      },
      {
        path: '/search',
        name: 'search',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "Search" */ /* webpackMode: "lazy" */ '@/frontend/Search')
      },
      {
        path: '/disclosure',
        name: 'disclosure',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "Disclosure" */ /* webpackMode: "lazy" */ '@/frontend/Disclosure')
      },
      {
        path: '/contact',
        name: 'contact',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "ContactUs" */ /* webpackMode: "lazy" */ '@/frontend/ContactUs')
      },
      {
        path: '/faq',
        name: 'faq',
        meta: { auth: false },
        component: () => import(/* webpackChunkName: "FAQ" */ /* webpackMode: "lazy" */ '@/frontend/FAQ')
      },
      {
        path: '/privacy',
        name: 'privacy',
        meta: { auth: false, isHome: true },
        component: () => import(/* webpackChunkName: "FAQ" */ /* webpackMode: "lazy" */ '@/frontend/Privacy')
      },
      {
        path: '/terms',
        name: 'terms',
        meta: { auth: false, isHome: true },
        component: () => import(/* webpackChunkName: "Terms" */ /* webpackMode: "lazy" */ '@/frontend/Terms')
      }
    ]
  },
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import(/* webpackChunkName: "DashboardLayout" */ /* webpackMode: "lazy" */ '@/layout/DashboardLayout'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { auth: true, title: 'TTL_DASHBOARD', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'SCHEDULING', 'FINANCE', 'BUSINESS', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "Dashboard" */ /* webpackMode: "lazy" */ '@/views/Dashboard')
      },
      {
        path: '/assessment_services',
        name: 'assessment_services',
        meta: { auth: true, title: 'TTL_SERVICES', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "AssessmentServices" */ /* webpackMode: "lazy" */ '@/views/AssessmentServices')
      },
      {
        path: '/generated_certs',
        name: 'generated_certs',
        meta: { auth: true, title: 'TTL_MANAGE_CERTS', roles: ['ADMIN', 'FINANCE', 'SUB_ADMIN', 'ASSESSOR', 'IT', 'BUSINESS', 'SCHEDULING', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "GeneratedCerts" */ /* webpackMode: "lazy" */ '@/views/GeneratedCerts')
      },
      {
        path: '/users',
        name: 'users',
        meta: { auth: true, AllowExport: true, title: 'TTL_CLIENTS', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'BUSINESS', 'SCHEDULING', 'FINANCE'] },
        component: () => import(/* webpackChunkName: "Users" */ /* webpackMode: "lazy" */ '@/views/Users')
      },
      {
        path: '/child',
        name: 'child',
        meta: { auth: true, isChild: true, title: 'TTL_CLIENT_SUB_ACCOUNT', roles: ['CLIENT', 'ADMIN', 'SUB_ADMIN', 'BUSINESS', 'SCHEDULING'] },
        component: () => import(/* webpackChunkName: "ChildList" */ /* webpackMode: "lazy" */ '@/views/ChildList')
      },
      {
        path: '/applications',
        name: 'applications',
        meta: { auth: true, AllowExport: true, title: 'TTL_APPLICATIONS', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'FINANCE', 'BUSINESS', 'SCHEDULING', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "Applications" */ /* webpackMode: "lazy" */ '@/views/Applications')
      },
      {
        path: '/logs',
        name: 'logs',
        meta: { auth: true, title: 'TTL_LOGS', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "Logs" */ /* webpackMode: "lazy" */ '@/views/Logs')
      },
      {
        path: '/country',
        name: 'country',
        meta: { auth: true, title: 'LBL_COUNTRY_LIST', menu: 'settings', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "StripeConnect" */ /* webpackMode: "lazy" */ '@/views/Country')
      },
      {
        path: '/translation',
        name: 'translation',
        meta: { auth: true, title: 'TTL_TRANSLATIONS', menu: 'settings', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "StripeConnect" */ /* webpackMode: "lazy" */ '@/views/Translation')
      },
      {
        path: '/stripe',
        name: 'stripe_connect',
        meta: { auth: true, title: 'TTL_STRIPE_CONNECT', menu: 'settings', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "StripeConnect" */ /* webpackMode: "lazy" */ '@/views/StripeConnect')
      },
      {
        path: '/staffs',
        name: 'staffs',
        meta: { auth: true, title: 'TTL_STAFF_ACCOUNT', menu: 'settings', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "Staffs" */ /* webpackMode: "lazy" */ '@/views/Staffs')
      },
      {
        path: '/tags',
        name: 'tags',
        meta: { auth: true, title: 'TTL_SERVICE_TAGS', menu: 'settings', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "Staffs" */ /* webpackMode: "lazy" */ '@/views/ServiceTags')
      },
      {
        path: '/business_teams',
        name: 'business_teams',
        meta: { auth: true, title: 'TTL_BUSINESS_TEAM', menu: 'settings', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "BusinessTeams" */ /* webpackMode: "lazy" */ '@/views/BusinessTeams')
      }
    ]
  },
  {
    path: '/',
    redirect: 'user',
    component: () => import(/* webpackChunkName: "SubPageLayout" */ /* webpackMode: "lazy" */ '@/layout/SubPageLayout'),
    children: [
      {
        path: '/profile',
        name: 'profile',
        meta: { auth: true, title: 'TTL_PROFILE', isProfile: true },
        component: () => import(/* webpackChunkName: "UserProfile" */ /* webpackMode: "lazy" */ '@/views/UserProfile')
      },
      {
        path: '/password',
        name: 'password',
        meta: { auth: true, title: 'TTL_CHANGE_PASSWORD' },
        component: () => import(/* webpackChunkName: "UserProfile" */ /* webpackMode: "lazy" */ '@/views/UserProfile')
      },
      {
        path: '/user/add',
        name: 'Add User',
        meta: { auth: true, title: 'TTL_ADD_USER', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "User" */ /* webpackMode: "lazy" */ '@/views/User')
      },
      {
        path: '/user/edit/:userkey',
        name: 'Edit User',
        meta: { auth: true, title: 'TTL_EDIT_USER', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'BUSINESS', 'FINANCE', 'SCHEDULING'] },
        component: () => import(/* webpackChunkName: "User" */ /* webpackMode: "lazy" */ '@/views/User')
      },
      {
        path: '/child/edit/:userkey',
        name: 'child-edit',
        meta: { auth: true, title: 'TTL_CLIENT_SUB_ACCOUNT', roles: ['CLIENT', 'ADMIN', 'SUB_ADMIN', 'BUSINESS'] },
        component: () => import(/* webpackChunkName: "ChildEdit" */ /* webpackMode: "lazy" */ '@/views/ChildEdit')
      },
      {
        path: '/application/add',
        name: 'Add Application',
        meta: { auth: true, title: 'TTL_ADD_APPLICATION', back: '/applications', roles: ['CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "Application" */ /* webpackMode: "lazy" */ '@/views/Application')
      },
      {
        path: '/application/edit',
        name: 'Edit Application',
        meta: { auth: true, title: 'TTL_EDIT_APPLICATION', back: '/applications', isApplication: true, roles: ['CLIENT', 'SUB_CLIENT', 'BUSINESS', 'ADMIN', 'SUB_ADMIN'] },
        component: () => import(/* webpackChunkName: "Application" */ /* webpackMode: "lazy" */ '@/views/Application')
      },
      {
        path: '/application/checklist/reject',
        name: 'Application Reject Checklist',
        meta: { auth: true, title: 'TTL_ASSESSMENT_CHECKLIST', roles: ['ASSESSOR', 'ADMIN', 'SUB_ADMIN'] },
        component: () => import(/* webpackChunkName: "Application" */ /* webpackMode: "lazy" */ '@/views/AssessmentChecklistReject')
      },
      {
        path: '/viewform',
        name: 'View Form',
        meta: { auth: true, fixedTopBar: true, title: 'LBL_VIEW_APPLICATION', back: '/applications', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'FINANCE', 'BUSINESS', 'SCHEDULING', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "ViewForm" */ /* webpackMode: "lazy" */ '@/views/ViewForm')
      },
      {
        path: '/certificate',
        name: 'View Certificate',
        meta: { auth: true, fixedTopBar: true, title: 'LBL_VIEW_CERIFICATE', roles: ['ADMIN', 'SUB_ADMIN', 'BUSINESS', 'ASSESSOR', 'FINANCE', 'IT', 'SCHEDULING', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "ViewCert" */ /* webpackMode: "lazy" */ '@/views/ViewCert')
      },
      {
        path: '/statement',
        name: 'View Statement',
        meta: { auth: true, fixedTopBar: true, title: 'LBL_VIEW_STATEMENT', roles: ['ADMIN', 'SUB_ADMIN', 'BUSINESS', 'ASSESSOR', 'FINANCE', 'IT', 'SCHEDULING', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT', 'SUB_CLIENT'] },
        component: () => import(/* webpackChunkName: "ViewCert" */ /* webpackMode: "lazy" */ '@/views/ViewCert')
      },
      {
        path: '/invoice/:key',
        name: 'Application Invoice',
        meta: { auth: true, title: 'LBL_PAYMENT_DETAILS', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'FINANCE', 'BUSINESS', 'SCHEDULING', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT'] },
        component: () => import(/* webpackChunkName: "Invoice" */ /* webpackMode: "lazy" */ '@/views/Invoice')
      },
      {
        path: '/certificate/invoice/:key',
        name: 'View Invoice',
        meta: { auth: true, title: 'LBL_PAYMENT_DETAILS', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'FINANCE', 'BUSINESS', 'SCHEDULING', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO', 'CLIENT'] },
        component: () => import(/* webpackChunkName: "Invoice" */ /* webpackMode: "lazy" */ '@/views/Invoice')
      },
      {
        path: '/staff/add',
        name: 'Add Staff',
        meta: { auth: true, title: 'TTL_ADD_STAFF', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "User" */ /* webpackMode: "lazy" */ '@/views/User')
      },
      {
        path: '/staff/edit/:userkey',
        name: 'Edit Staff',
        meta: { auth: true, title: 'TTL_EDIT_STAFF', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "User" */ /* webpackMode: "lazy" */ '@/views/User')
      },
      {
        path: '/team/add',
        name: 'Add Team',
        meta: { auth: true, title: 'TTL_ADD_TEAM', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "BusinessTeam" */ /* webpackMode: "lazy" */ '@/views/BusinessTeam')
      },
      {
        path: '/team/edit/:teamkey',
        name: 'Edit Team',
        meta: { auth: true, title: 'TTL_EDIT_TEAM', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "BusinessTeam" */ /* webpackMode: "lazy" */ '@/views/BusinessTeam')
      },
      {
        path: '/assessment_service/add',
        name: 'assessment_service_add',
        meta: { auth: true, title: 'TTL_ADD_SERVICE', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "AssessmentService" */ /* webpackMode: "lazy" */ '@/views/AssessmentService')
      },
      {
        path: '/assessment_service/edit/:key',
        name: 'assessment_service_edit',
        meta: { auth: true, title: 'TTL_EDIT_SERVICE', roles: ['ADMIN', 'SUB_ADMIN', 'IT'] },
        component: () => import(/* webpackChunkName: "AssessmentService" */ /* webpackMode: "lazy" */ '@/views/AssessmentService')
      },
      {
        path: '/assessment_service/view/:key',
        name: 'assessment_service_view',
        meta: { auth: true, title: 'TTL_VIEW_SERVICE', roles: ['ADMIN', 'SUB_ADMIN', 'IT', 'SCHEDULING', 'BUSINESS', 'ASSESSOR', 'COO', 'DEPUTYCEO', 'CEO'] },
        component: () => import(/* webpackChunkName: "AssessmentService" */ /* webpackMode: "lazy" */ '@/views/AssessmentService')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    meta: {},
    component: () => import('@/views/404.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/404' }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const userToken = store.getters['user/getToken']
  const userData = store.getters['user/getData']?.UserData || {}
  const IsTCUpdated = store.getters['user/getData']?.IsTCUpdated

  let { auth, login, isProfile, isHome, isChild } = to.meta

  if (IsTCUpdated && !isHome) {
    return next('/home')
  }

  if (isChild && !helpers.hasRoles(['CLIENT']) && !to.query.key) {
    return next('/users')
  }

  if (auth && !userToken) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (login && userToken) {
    next('/dashboard')
  } else {
    //added guard
    //https://trello.com/c/9ZyM2SKW/115-comments-for-client-profile
    if (auth && userData?.UserRole == 'CLIENT' && !userData?.IsHaveOrganization && !isProfile) {
      return next({ path: '/profile', query: { redirect: to.fullPath } })
    }

    if (to.meta.roles) {
      if (helpers.hasRoles(to.meta.roles)) {
        next()
      } else {
        next('/dashboard')
      }
    } else {
      next()
    }
  }
})

export default router
