import { extendedConfigs } from '@/utils/configs'
import { io } from 'socket.io-client'

/* MAIN INSTANCE HERE */
let socketReceiveCallback = {}
let main = {}

//global function here, like join, leave, etc.
let socket = {
  isConnected() {
    return main.connected ? true : false
  },
  unassignSocketCallback(type) {
    delete socketReceiveCallback[type]
  },
  assignSocketCallback(type, cb) {
    socketReceiveCallback[type] = cb
  },
  leave(room) {
    socket.log('Leaving room ' + room)
    main.emit('leave', room)
  },
  join(room) {
    socket.log('Joining room ' + room)
    main.emit('join', room)
  }
}

const install = (app) => {
  app.config.globalProperties.$socket = socket
}

export { install as default, socket }
