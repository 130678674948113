import axios from 'axios'
import { configs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'
import { store } from '@/store'

//for loader
let requestTimeout = null

const HTTP = axios.create({
  baseURL: configs.baseURL,
  timeout: configs.axiosTimeout
})

const startLoading = async () => {
  if (requestTimeout) {
    clearTimeout(requestTimeout)
    requestTimeout = null
  }
  await store.dispatch('page/setData', { isFetching: true })
}

const doneLoading = async () => {
  requestTimeout = setTimeout(async () => {
    await store.dispatch('page/setData', { isFetching: false })
    requestTimeout = null
  }, 300)
}

HTTP.interceptors.request.use((config) => {
  const userToken = store.getters['user/getToken']
  if (userToken && userToken !== '') {
    config.headers.Authorization = `Bearer ${userToken}`
  }
  //language code
  config.headers.AppLanguage = store.getters['translation/getLanguage']
  return config
})

const isValidResponse = (res) => {
  if (typeof res === 'undefined') return false
  return true
}

const formulateUrl = (apiLink) => {
  return `${configs.baseURL}${apiLink}`
}

const formulateCMSUrl = (apiLink) => {
  if (!apiLink) return ''
  return `${configs.cmsURL}${apiLink}`
}

const doneUpload = () => {
  store.dispatch('page/setData', { isSubmitting: false })
}

const uploadProgress = () => {
  store.dispatch('page/setData', { isSubmitting: true })
}

const uploadError = (err) => {
  let errorCode = err ? err.toString() : ''
  errorCode = errorCode.replace('Error: ', '')

  store.dispatch('page/setData', { isSubmitting: false })
  helpers.catchError({ Code: errorCode || 'ERR_UPLOAD_ERR', Message: errorCode || 'Upload failed, try again later' })
}

const getHeaders = () => {
  const userToken = store.getters['user/getToken']
  return userToken && userToken !== '' ? { Authorization: `Bearer ${userToken}` } : {}
}

/* AXIOS REQUESTS: GET, PSOT have own try catch block so we can add global configs/error catchers in the future */

const get = async (apiLink, query, skipLoading = false) => {
  try {
    if (!skipLoading) startLoading()

    let res = await HTTP.get(`${apiLink}${!helpers.isBlank(query) ? helpers.objToQueryString(query || {}) : ''}`)
    //after get
    if (!skipLoading) doneLoading()

    if (res && res.status === 200 && res?.data?.data) {
      return res.data.data
    }
    return null
  } catch (err) {
    if (!skipLoading) doneLoading()
    helpers.catchError(err)
  }
}

const post = async (apiLink, post, throwError = true, isLoading = true) => {
  try {
    if (isLoading) {
      store.dispatch('page/setData', { isSubmitting: true })
    }

    let res = await HTTP.post(apiLink, post)
    //after post
    if (isLoading) {
      store.dispatch('page/setData', { isSubmitting: false })
    }
    if (res && res.status === 200 && res?.data?.data) {
      return res.data.data
    }
    return null
  } catch (err) {
    if (isLoading) {
      store.dispatch('page/setData', { isSubmitting: false })
    }
    if (throwError) helpers.catchError(err)
    if (!throwError) return { Error: err?.response?.data?.Message }
  }
}

const dataOnly = async (apiLink, post) => {
  try {
    let res = await HTTP.post(apiLink, post)
    if (res && res.status === 200 && res?.data?.data) {
      return res.data.data
    }
    return null
  } catch (err) {
    return null
  }
}

const install = (app) => {
  app.config.globalProperties.$HTTP = HTTP
  app.config.globalProperties.$axios = {
    getHeaders,
    uploadProgress,
    uploadError,
    formulateCMSUrl,
    formulateUrl,
    doneUpload
  }
}

export { install as default, HTTP as $HTTP, get, post, dataOnly, isValidResponse, formulateUrl, formulateCMSUrl }
