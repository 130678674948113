import dayjs from 'dayjs'
import _ from 'lodash'

import { get, isValidResponse } from '@/utils/axios'

const data = {
  languageCode: 'EN',
  textSize: 'small',
  translations: {}
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getLanguage: (state) => {
    return state.data.languageCode
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  setLanguage: async (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    let lang = ['EN', 'CHS', 'CHT']
    let translations = {}

    for (let LanguageCode of lang) {
      const translationList = await get(`/translation/public`, { LanguageCode })
      if (!isValidResponse(translationList)) return

      translations[LanguageCode] = translationList
    }

    context.commit('UPDATE_DATA', { translations })
  }
}

export const translation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
